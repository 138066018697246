<template>
    <div class="auth">
        <div v-if="!login_status" class="reminder_text">填写报名信息前请先点击授权！</div>
        <div v-else class="reminder_text">验证账号前请先点击按钮完成授权！</div>
        <div class="img_outer">
            <img src="@/assets/img/logo.png" @click="getAuthorizationUrl" />
        </div>
        <div @click="getAuthorizationUrl" style="border:1px solid lightgray;width: 30%;height: 30px;line-height: 30px;
		margin: 0 auto;text-align: center;margin-top: 10%;background-color: #42b983;color: white;border-radius: 30px;">
            微信授权
        </div>
    </div>
</template>

<script>
// import {
// 	auth_url
// } from '@/api/login.js'
import axios from "axios";

export default {
    data() {
        return {
            param: '', // `${config.baseServerPageUrl}index.html#/empty?${param}`,
            login_status: false,
            bind: false
        }
    },
    created() {
        this.$store.commit("hideTabbar");
        this.init()
        history.pushState(null, null, document.URL); // 禁止网页返回上一页
        window.addEventListener('popstate', function () {
            window.WeixinJSBridge.call("closeWindow");
        });
    },
    methods: {
        // 获取微信授权url
        getAuthorizationUrl() {
            if (this.bind) {
                axios.defaults.baseURL = process.env.VUE_APP_BASE_API2;
                axios({
                    url: "/get_wechat_auth_url",
                    method: 'get',
                    params: {
                        redirect_url: `${process.env.VUE_APP_BASE_URL}WXBind?${this.param}`
                    }
                }).then(res => {
                    const authorizationUrl = res.data.data.data.wechat_auth_url
                    window.location.replace(authorizationUrl)
                })
            }

        },
        // 拿到当前页面的链接
        init() {
            let qrCodeUrl = window.location.href;
            const temp = qrCodeUrl.split('?')
            this.param = temp[1]
            if (temp[1].split("&")[0].split('=')[0] == 'admin_id') {
                this.bind = true
            }
            this.getAuthorizationUrl()
        }
    }
}
</script>

<style scoped>
.reminder_text {
    text-align: center;
    margin: 100px 0 80px 0;
}

.img_outer {
    display: flex;
    justify-content: center;
}

img {
    width: 66px;
    height: 66px;
}
.auth {
    text-align: center;
    font-size: 28px;
}
</style>
